.user-tab-panel {
}

.users-tab {
  .MuiTabs-flexContainer {
    height: 72px;

    .MuiButtonBase-root {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;

      color: #767676;
      text-transform: none;
    }

    .Mui-selected {
      color: #000;
    }
  }
}

.user-tab-container {
  .user-search {
    position: absolute;
    top: 16px;
    right: 24px;

    background: #ffffff;
    padding: 0 16px;
    border: 2px solid #cecece;
    border-radius: 8px;

    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    width: 297px;
    // height: 40px;

    color: #a4a4a4;
  }
  .sort-favorite {
    position: absolute;
    top: 16px;
    right: 360px;
  }
}
