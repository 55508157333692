.dropzone-wrap {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.dropzone-container {
  height: 160px;
  width: 432px;
  background: #e8e8e8;
  border-radius: 8px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 24px;
}
