body {
  margin: 0;
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.midol-heading {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  word-wrap: break-word;
  color: #000000;
}

.midol-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  word-wrap: break-word;
  color: #000000;
}

.midol-sub-title {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  word-wrap: break-word;
}
.midol-text {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  color: #000000;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

.Toastify__toast-container {
  width: 360px !important;
  @media (min-width: 768px) {
    width: 480px !important;
  }

  .Toastify__toast-body {
    padding-right: 20px;
  }
}

.Toastify__close-button {
  color: #000000 !important;
}

.Toastify__toast {
  //border: 1px solid;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
}

.Toastify__toast--info {
  background: #effffe !important;
  border-color: #4b9c95;
  color: #4b9c95;
}
.Toastify__toast--success {
  background: #e3fcef !important;
  box-shadow: 0 6px 6px rgba(23, 43, 77, 0.04), 0 12px 12px rgba(23, 43, 77, 0.06);
  border-radius: 12px !important;
  color: #006644 !important;
}
.Toastify__toast--warning {
  background: #fef9d1 !important;
  border-color: #edca1e;
  color: #edca1e;
}
.Toastify__toast--error {
  background: #ffebe6 !important;
  border: 1px solid #ffebe6;
  box-shadow: 0 6px 6px rgba(23, 43, 77, 0.04), 0 12px 12px rgba(23, 43, 77, 0.06);
  border-radius: 12px !important;
  color: #ff3131 !important;
}
.Toastify__close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #787878 !important;
}
