.statistic-card-container {
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.04), 0px 6px 12px rgba(50, 50, 50, 0.08);
    border-radius: 12px;
    margin-right: 16px;
    margin-left: 16px;
    min-width: 256px;
    .card-title {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        display: block;
        margin-bottom: 8px;
    }
    .card-content {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 36px;
        color: #532F91;
        display: block;
    }
}