.order-btn-wrap {
  padding: 8px 12px !important;
  height: 36px !important;
  border-radius: 36px !important;

  font-family: "Readex Pro" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
  background: #ffffff !important;
  border: 2px solid #000000 !important;
  color: #000 !important;

  &:hover {
    color: #ffffff !important;
    background: #000000 !important;
  }

  &.selected {
    color: #ffffff !important;
    background: #000000 !important;
    border: 2px solid hsl(0, 0%, 0%) !important;
  }
}

.users-tab {
  .MuiTabs-flexContainer {
    gap: 24px;
    height: 72px;

    .MuiButtonBase-root {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;

      color: #767676;
      text-transform: none;
      padding: 0;
    }

    .Mui-selected {
      color: #000;
      padding: 0;
    }
  }

  .MuiTabs-indicator {
    height: 4px;
    background-color: #000;
  }
}

.user-tab-container {
  .user-search {
    position: absolute;
    top: 16px;
    right: 24px;

    background: #ffffff;
    padding: 0 16px;
    border: 2px solid #cecece;
    border-radius: 8px;

    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    width: 297px;
    // height: 40px;

    color: #a4a4a4;
  }
}
