.midol-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.midol-modal-wrap {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.midol-modal-wrap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.modal-container {
  gap: 16px;
  padding-top: 16px;

  .midol-modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    gap: 8px;
    height: 48px;
    border: 2px solid #532f91;
    border-radius: 8px;
    text-transform: none;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .midol-secondary-btn {
    background: #ffffff;
    color: #000;
    border: 2px solid #000000;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .modal-list-container {
    overflow: auto;
  }
}
