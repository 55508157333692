.payment-action {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #532f91;

  cursor: pointer;
}

.payment-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .payment-modal-title {
    font-family: "Manrope";
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;

    margin: 0;
    padding-top: 24px;
  }

  .payment-modal-sub {
    font-family: "Readex Pro";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;

    margin: 0;
    padding-top: 8px;
  }
}

.transaction-approve {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #006644;
}

.transaction-reject {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #b92500;
}
