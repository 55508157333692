.table-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 570px;
  justify-content: space-between;

  .table-container {
    display: flex;
    flex: 1;
    max-height: 510px;
  }

  .table-pagination {
    overflow: hidden;
    min-height: 60px !important;
  }
}

.user-info {
  display: flex;

  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #e8e8e8;
    margin-right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-name {
    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .user-email {
    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #767676;
  }
}

.user-id {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #532f91;
  cursor: pointer;
}

.user-type-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 56px;
  height: 28px;
  background: #532f91;
  border-radius: 20px;
  text-transform: uppercase;
}

.user-idol {
  background: #ec156b;
  font-weight: bold;
}
.user-idol .user-type {
  font-weight: bold;
}

.user-type {
  font-family: "Readex Pro";
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.MuiList-root {
  .user-menu-item:hover {
    color: #ee1b1b;
    font-weight: bold;
  }
}

.profile-table-head,
.profile-table-body {
  white-space: nowrap;
}

.profile-table-head > .profile-cell {
  background: #f8f8f8;
  border-bottom: 1px solid #e8e8e8;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 12px 24px;
}
.profile-table-body > tr > td {
  font-family: "Readex Pro";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
  padding: 12px 24px;
}

.modal-row {
  padding-top: 24px;

  .modal-title {
    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .modal-sub-title {
    font-family: "Readex Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
    word-break: break-word;
    color: #000000;
  }
}
