.custom-title {
  .title {
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }

  .sub-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .title-primary {
    color: #532f91;
  }
  .title-secondary {
    color: #ec156b;
  }
}
