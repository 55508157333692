.right-container {
  display: flex;
  align-items: center;
}  

.horiz-menu > div {
  display: inline-block;
}

.svg-hover {
  &:hover {
    color: #532f91;
  }
}

.category {
  &:hover {
    margin-bottom: -10px;
    padding-bottom: 10px;
    border-bottom: 4px #532f91 solid;
  }
}

.popover {
  padding: 16px;
  margin-top: 2px;
  border: 1px solid #f8f8f8;
  border-radius: 3px;

  //display: grid;
  //grid-template-columns: repeat(2, minmax(0, 1fr));
  //grid-template-rows: repeat(3, minmax(0, 1fr));
  //gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
}

.avatar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .avatar-full-name {
    font-weight: bold;
  }
  .avatar-component-container {
    margin-left: 8px;
    cursor: pointer;
  }
}

