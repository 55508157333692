.toastify-container {
  display: flex;
  align-items: center;
}

.toastify-content {
  padding: 0;
  margin: 0;
  margin-left: 12px;
  color: inherit;
}
