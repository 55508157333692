.pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  padding: 12px 16px;

  .user-pagination-item > ul > li > button {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
  .user-pagination-item
    > ul
    > li
    > button.MuiPaginationItem-rounded.Mui-selected {
    background-color: #000000;
    color: #ffffff;
  }

  .pagination-selector {
    margin-left: 8px;
    gap: 8px;
    // width: 115px;
    height: 36px;
    background: #ffffff;

    border: 1px solid #8c8c8c;
    border-radius: 4px;
  }

  .pagination-text {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
}
